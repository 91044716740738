import React, { useRef, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';

export default function PCMModel() {
    const { scene } = useGLTF('/capv2.glb');
    const modelRef = useRef();
  
    // Set rotation to specific angles (e.g., 90 degrees on the Y-axis)
    useEffect(() => {
      if (modelRef.current) {
        modelRef.current.rotation.set(-Math.PI / 2, 0, 0);// Rotate to 90 degrees (PI/2 radians) on the different axes
      }
    }, [scene]);
   
    const scale_value = 3;
    return <primitive ref={modelRef} object={scene} scale={[scale_value, scale_value, scale_value]} position={[-0.5, 0, 0]} />;
  }