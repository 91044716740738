/* eslint-disable */
import React from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";

import img1 from "../assets/images/portfolio/windturbine_alone.jpg";

import * as THREE from 'three'
import { Canvas } from "@react-three/fiber";
import { Sparkles, Shadow, ContactShadows, Billboard, Environment, BakeShadows, OrbitControls } from '@react-three/drei';
import { LayerMaterial, Depth } from 'lamina';
import PCMModel from './PCMModel';

const ThreeDiv = () => {
  return (
    <div 
      style={{
        display: 'flex',        // Enable flexbox
        justifyContent: 'center', // Center horizontally
        alignItems: 'center',   // Center vertically
        backgroundColor: 'grey',
        width: '80%',
        height: '800px',
        margin: '0 auto',       // Optional: This ensures the div is centered horizontally in its container
      }}
    >
    <Canvas camera={{ position: [6, 6, 12], fov: 50 }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 10]} intensity={1} />
              <Environment
                  files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/4k/glass_passage_4k.hdr"
                  ground={{ height: 5, radius: 40, scale: 20 }}
              />
              <PCMModel />
              <OrbitControls autoRotateSpeed={0.85} zoomSpeed={0.75} minDistance={0} maxDistance={10} />
          </Canvas>
      </div>
  );
};

export default ThreeDiv;
